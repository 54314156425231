.recruitment-ratios-container {
  padding: 20px;
}

.step-label {
  color: #888;
  margin-bottom: 4px;
}

.step-value {
  color: #000;
}

.arrow-container {
  display: flex;
  align-items: center;
  position: relative;
}

.arrow-and-ratio-container {
  display: flex;
  align-items: center;
}

.ratio-text {
  background-color: #e3f2fd;
  padding: 2px 6px;
  border-radius: 4px;
  position: absolute;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratio-text::after {
  content: "";
  position: absolute;
  border-left: 4px dotted #e3f2fd;
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.top {
  top: -25px;
}

.top::after {
  top: 100%;
}

.bottom {
  bottom: -25px;
}

.bottom::after {
  bottom: 100%;
}

.arrow-icon {
  color: #e3f2fd;
  margin-top: 5px;
}

.fill-rate-box {
  padding-left: 20px;
  color: #2a70ea;
}
